import styles from "./Homepage.module.scss";
import CarouselSkills from "../../components/CarouselSkills/CarouselSkills";
// skills
import html from "../../assets/icons/html.png";
import css from "../../assets/icons/css.png";
import scss from "../../assets/icons/scss.png";
import ts from "../../assets/icons/ts.png";
import js from "../../assets/icons/js.png";
import b from "../../assets/icons/b.png";
import figma from "../../assets/icons/figma.png";
import flutter from "../../assets/icons/flutter.png";
import wordpress from "../../assets/icons/wordpress.png";
// info

import mail from "../../assets/info/mail.png";

// io
import iocut from "../../assets/iocut.jpg";
import cvimage from "../../assets/cvimage.jpg";
import TimelineExperience from "../../components/TimelineExperience/TimelineExperience";
import TimelineInstruction from "../../components/TimerlineInstruction/TimelineInstruction";

import splash from "../../assets/splash.png";
import chooseFriends from "../../assets/choosefriends.png";
import opportunity from "../../assets/opportunity.png";
import { Reveal } from "../../components/Reveal/Reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//icon
import {
  faUser,
  faCakeCandles,
  faLocationDot,
  faGraduationCap,
  faMountainSun,
  faBicycle,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../../components/FormContact/ContactForm";

const Homepage = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  // const ref = useRef(null);
  // const inView = useInView(ref, { once: true });
  // const mainControl = useAnimation();

  // useEffect(() => {
  //   if (inView) {
  //     mainControl.start("visible");
  //   }
  // }, [inView]);

  return (
    <div className={styles["homepage"]}>
      <div className={styles["homepage__inline-header"]}>
        <img className={styles["homepage__img"]} src={cvimage} alt="" />
        <Reveal>
          <h2 className={styles["homepage__presentation"]}>
            Ciao! Mi chiamo <strong>Agnese</strong> e sono una{" "}
            <span className={styles["homepage__strong"]}>Sviluppatrice Web</span> e una
            <span className={styles["homepage__strong"]}> Social Media Manager</span>
          </h2>
        </Reveal>
      </div>

      <Reveal>
        <h3 className={styles["homepage__title"]}>CHI SONO</h3>
      </Reveal>
      <div className={styles["homepage__container-about-me"]}>
        <div className={styles["homepage__container-info"]}>
          <div>
            <div className={styles["homepage__container-info__inline"]}>
              <FontAwesomeIcon color="white" icon={faUser} />
              <p className={styles["homepage__container-info__key"]}>Nome:</p>
              <p className={styles["homepage__container-info__value"]}>Agnese Morlin</p>
            </div>

            <div className={styles["homepage__container-info__inline"]}>
              <FontAwesomeIcon color="white" icon={faCakeCandles} />
              <p className={styles["homepage__container-info__key"]}>Compleanno:</p>
              <p className={styles["homepage__container-info__value"]}>09/08/1996</p>
            </div>

            <div className={styles["homepage__container-info__inline"]}>
              <FontAwesomeIcon color="white" icon={faLocationDot} />
              <p className={styles["homepage__container-info__key"]}>Luogo:</p>
              <p className={styles["homepage__container-info__value"]}>Nove, VI</p>
            </div>

            <div className={styles["homepage__container-info__inline"]}>
              <FontAwesomeIcon color="white" icon={faGraduationCap} />
              <p className={styles["homepage__container-info__key"]}>Studi:</p>
              <p className={styles["homepage__container-info__value"]}>
                Università degli studi di Padova
              </p>
            </div>
          </div>

          <img src={iocut} className={styles["homepage__img-center"]} alt="" />

          <div>
            <div className={styles["homepage__container-info__inline"]}>
              <FontAwesomeIcon color="white" icon={faMountainSun} />
              <p className={styles["homepage__container-info__key"]}>Interessi:</p>
              <p className={styles["homepage__container-info__value"]}>Montagna, Corsa</p>
            </div>

            <div className={styles["homepage__container-info__inline"]}>
              <FontAwesomeIcon color="white" icon={faBriefcase} />
              <p className={styles["homepage__container-info__key"]}>GitHub:</p>
              <a
                className={styles["homepage__container-info__key"]}
                href="https://github.com/96Agnese"
              >
                Agnese
              </a>
            </div>

            <div className={styles["homepage__container-info__inline"]}>
              <img src={mail} height={24} width={24} alt="" />
              <p className={styles["homepage__container-info__key"]}>Email:</p>
              <a
                className={styles["homepage__container-info__key"]}
                href="mailto:agnesemorlin@gmail.com?subject=Scrivimi dal mio sito&body=Ciao Agnese, vorrei sapere di più su..."
              >
                Agnese Morlin
              </a>
            </div>

            <div className={styles["homepage__container-info__inline"]}>
              <FontAwesomeIcon color="white" icon={faBicycle} />
              <p className={styles["homepage__container-info__key"]}>Hobbies:</p>
              <a
                className={styles["homepage__container-info__key"]}
                href="http://www.parcodelsojo.it/"
              >
                Parco del Sojo
              </a>
            </div>
          </div>
        </div>
      </div>

      <Reveal>
        <section className={styles["homepage__subtitle"]}>
          <h2>Il mio viaggio nel mondo Tech e del Marketing</h2>
          <p>
            Tutto è iniziato nel 2021, quando ho scoperto il framework <strong>Flutter</strong>{" "}
            grazie a un bando regionale. Da quel momento, la mia curiosità per il mondo della
            tecnologia è cresciuta sempre di più, trasformandosi in una vera passione. Imparare
            Flutter è stato solo il primo passo: volevo capire meglio come nascono i siti web, come
            funzionano e come crearli da zero.
          </p>
          <p>
            Così, mi sono dedicata allo studio dei principali{" "}
            <strong>linguaggi di programmazione</strong>, mettendo alla prova ciò che imparavo per
            creare il mio primo sito web. Ora sono qui, pronta a condividere le mie competenze e
            aiutarti a realizzare il tuo sito, trasformando le tue idee in realtà.
          </p>
          <p>
            Parallelamente, la mia passione per i <strong>social media</strong> è nata già da
            ragazzina. Mi ha sempre affascinato il mondo del
            <strong> marketing</strong>, delle strategie e delle comunicazioni digitali. Ho iniziato
            con il
            <strong> Parco del Sojo</strong>, un'esperienza che mi ha permesso di crescere e di
            comprendere ancora meglio come catturare l’attenzione del pubblico.
          </p>
          <p>
            Se stai cercando qualcuno che possa guidarti nel mondo del tuo{" "}
            <strong> sito personale</strong> o supportarti con strategie di
            <strong> marketing mirate</strong>, scrivimi sotto nel form!
          </p>
        </section>
      </Reveal>
      <Reveal>
        <h3 id="skills" className={styles["homepage__title"]}>
          COMPETENZE
        </h3>
      </Reveal>
      <CarouselSkills />

      <div className={styles["homepage__align-skills"]}>
        <img src={html} alt="20" height={70} />

        <img src={css} alt="20" height={70} />
        <img src={scss} alt="20" height={70} />
        <img src={ts} alt="20" height={70} />
        <img src={js} alt="20" height={70} />
        <img src={b} alt="20" height={70} />
        <img src={figma} alt="20" height={70} />
        <img src={flutter} alt="20" height={70} />
        <img src={wordpress} alt="20" height={70} />
      </div>

      <Reveal>
        <h3 id="experiences" className={styles["homepage__title"]}>
          ESPERIENZE
        </h3>
      </Reveal>

      <TimelineExperience />

      <Reveal>
        <h3 id="istruction" className={styles["homepage__title"]}>
          ISTRUZIONE
        </h3>
      </Reveal>
      <TimelineInstruction />

      <Reveal>
        <h3 id="projects" className={styles["homepage__title"]}>
          PROGETTI
        </h3>
      </Reveal>
      {/* <Card hoverable cover={<img alt="ehilapp" src={splash} />}>
        <Meta title="Ehilapp" description="React Native" />
      </Card> */}

      <div className={styles["homepage__direction"]}>
        <div className={styles["homepage__card"]}>
          <h2 className={styles["homepage__title-card"]}> - Ehilapp</h2>
          <h4 className={styles["homepage__subtitle-card"]}>
            Caritas Diocesana Veronese e CISL-Verona
          </h4>
          <div className={styles["homepage__inline"]}>
            <img src={splash} alt="" height={200} />
            <img src={chooseFriends} alt="" height={200} />
            <img src={opportunity} alt="" height={200} />
          </div>
        </div>

        <div className={styles["homepage__texts-ehilapp"]}>
          <h1 style={{ color: "white" }}>Ehilapp</h1>
          <p style={{ color: "white" }}>
            <span style={{ fontWeight: "bold", color: "white" }}>Anno:</span> 2022
          </p>
          <p style={{ color: "white" }}>
            {" "}
            <span style={{ fontWeight: "bold", color: "white" }}>Azienda:</span> Rawfish
          </p>
          <p className={styles["homepage__desc-ehilapp"]}>
            E' un’app creata in
            <span style={{ fontWeight: "bold", color: "white", paddingLeft: 4, paddingRight: 4 }}>
              React Native
            </span>
            con lo scopo di aiutare i cittadini a trovare i bonus, o le opportunità più vicine a
            loro. Ogni utente può decidere quale seguire tra i 7 amici presenti, semplicemente
            accendendo o spegnendo la relativa icona. All’interno dell’app il cittadino sarà in
            grado di visionare le opportunità non solo dello Stato italiano, ma anche del proprio
            Comune di residenza.
          </p>
          <a style={{ color: "white", fontWeight: "bold" }} href="https://ehilapp.it/">
            Scarica anche tu l'app!
          </a>
        </div>
      </div>
      {/* <Reveal> */}
      <ContactForm />
      {/* </Reveal> */}
    </div>
  );
};

export default Homepage;
