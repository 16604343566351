import react from "react";
import styles from "./ContactForm.module.scss";
import Swal from "sweetalert2";
import process from "process";

const ContactForm: React.FC = () => {
  const onSubmit = async (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const maybeApiKey: string = process.env.REACT_APP_CLIENT_KEY_ACCESS_KEY ?? "missing API Key";

    // Aggiungi l'access_key dalla variabile d'ambiente
    formData.append("access_key", maybeApiKey);

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      Swal.fire({
        title: "Mail inviata!",
        text: "Messaggio inviato con successo",
        icon: "success",
      });
    }
  };

  return (
    <div className={styles["contact-form"]}>
      <h2 className={styles["contact-form__title"]}>Hai qualche curiosità? Contattami 😉</h2>
      <form onSubmit={onSubmit}>
        <div className={styles["contact-form__name-inline"]}>
          <div className={styles["contact-form__form-group"]}>
            <label htmlFor="name">Nome</label>
            <input type="text" id="name" name="name" required />
          </div>

          <div className={styles["contact-form__form-group"]}>
            <label htmlFor="surname">Cognome</label>
            <input type="text" id="surname" name="surname" required />
          </div>
        </div>

        <div className={styles["contact-form__form-group"]}>
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" required />
        </div>

        <div className={styles["contact-form__form-group"]}>
          <label htmlFor="message">Messaggio</label>
          <textarea id="message" name="message" required />
        </div>

        <button type="submit" className={styles["contact-form__submit-button"]}>
          Invia
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
